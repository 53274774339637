enum FACULTIES {
	FACULTIES = '/api/curriculum/faculties',
	FACULTIES_BY_ID = '/api/curriculum/faculties/'
}

enum FLOWS {
	FLOWS = '/api/curriculum/flows',
	FLOWS_BY_ID = '/api/curriculum/flows/',
	FLOW_DISTRIBUTION = '/api/curriculum/flows/distribution'
}

enum GROUPS {
	GROUPS = '/api/curriculum/groups',
	GROUPS_BY_ID = '/api/curriculum/groups/',
	GROUPS_DEGREE_BY_GROUP_ID = '/api/curriculum/groups/main/degree/',
	GET_STUDENTS_COUNT_BY_FACULTY = '/api/curriculum/main/studentsCountBySubjects',
	GROUP_DISTRIBUTION = '/api/curriculum/groups/distribution'
}

enum CURRICULUM {
	LESSON_MODELS_GROUPS = '/api/curriculum/lessonGroups',
	LESSON_MODELS = '/api/curriculum/main',
	SCHEDULE_GENERATE = '/api/curriculum/schedule/generate',
	EXPORT_LESSON_MODEL_PDF = '/api/curriculum/main/export/pdf'
}

enum LESSONS_LIST {
	LESSONS_WEEKS = '/api/curriculum/lessonsList',
	LESSONS_WEEK_BY_ID = '/api/curriculum/lessonsList/'
}

enum SCHEDULE {
	SCHEDULE = '/api/curriculum/schedule',
	SCHEDULE_BY_GROUP_ID = '/api/curriculum/groups/main/schedule/'
}

export default {
	FACULTIES,
	FLOWS,
	GROUPS,
	CURRICULUM,
	LESSONS_LIST,
	SCHEDULE
};
