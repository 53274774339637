import axiosClient from 'utils/axiosClient';
import api from 'api';
import {ISubjectGroup} from 'pages/Curriculum/Main/SinglePlan/Main';

type LessonCurriculumInfoProps =
	| {
			semesterID: number;
			courseID: number;
			subjectID: number;
			subjectsTeachingLanguageID: number;
	  }
	| {pagination: 0};

const getLessonsModelsGroups = async (params?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.CURRICULUM.LESSON_MODELS_GROUPS, {params});
	return response.data;
};

const getLessonsModels = async (id: number, params?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + id, {
		params
	});
	return response.data;
};

const getCurriculumSubjectGroups = async (id: number, params?: any) => {
	const response = await axiosClient.get(
		api.CURRICULUM.CURRICULUM.LESSON_MODELS_GROUPS + '/' + id + '/subjectGroups',
		{
			params
		}
	);
	return response.data;
};

const getLessonsModelById = async (groupID: number, id: number) => {
	const response = await axiosClient.get(
		api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + groupID + '/' + id
	);
	return response.data;
};

const getLessonCurriculumInfo = async (groupID: number, params: LessonCurriculumInfoProps) => {
	const response = await axiosClient.get(
		api.CURRICULUM.GROUPS.GROUPS_BY_ID + 'main/curriculum/' + groupID,
		{
			params
		}
	);
	return response.data;
};

const createLessonsModelsGroup = async (data: any) => {
	const response = await axiosClient.post(api.CURRICULUM.CURRICULUM.LESSON_MODELS_GROUPS, data);
	return response.data;
};

const createLessonsModels = async (data: any) => {
	const response = await axiosClient.post(api.CURRICULUM.CURRICULUM.LESSON_MODELS, data);
	return response.data;
};

const updateLessonsModelsGroup = async (data: any, modelID: number) => {
	const response = await axiosClient.patch(
		api.CURRICULUM.CURRICULUM.LESSON_MODELS_GROUPS + '/' + modelID,
		data
	);
	return response.data;
};

const updateLessonsModels = async (data: any, modelID: number) => {
	const response = await axiosClient.patch(api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + modelID, data);
	return response.data;
};

const deleteLessonsModelsGroup = async (ids: number) => {
	const response = await axiosClient.delete(api.CURRICULUM.CURRICULUM.LESSON_MODELS_GROUPS + '/' + ids);
	return response.data;
};

const deleteLessonsModels = async (ids: number) => {
	const response = await axiosClient.delete(api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + ids);
	return response.data;
};

const createLessonType = async (data: any, id: number) => {
	const response = await axiosClient.post(api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + id, data);
	return response.data;
};

const generateSchedule = async (data: any) => {
	const response = await axiosClient.post(api.CURRICULUM.CURRICULUM.SCHEDULE_GENERATE, data);
	return response.data;
};

const updateLessonType = async (data: any, modelID: number, id: number) => {
	const response = await axiosClient.patch(
		api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + modelID + '/' + id,
		data
	);
	return response.data;
};

const deleteLessonType = async (modelID: number, ids: number) => {
	const response = await axiosClient.delete(
		api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + modelID + '/' + ids
	);
	return response.data;
};

const getCurriculumExamTypes = async (id: number, params?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + id + '/credit', {
		params
	});
	return response.data;
};

const createCurriculumExamType = async (data: any, id: number) => {
	const response = await axiosClient.post(
		api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + id + '/credit',
		data
	);
	return response.data;
};

const updateCurriculumExamType = async (data: any, id: number, styleID: number) => {
	const response = await axiosClient.patch(
		api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + id + '/credit/' + styleID,
		data
	);
	return response.data;
};

const updateCurriculumSubjectGroups = async (
	modelGroupID: number,
	subjectGroupID: number,
	data: Partial<ISubjectGroup>
) => {
	const response = await axiosClient.patch(
		api.CURRICULUM.CURRICULUM.LESSON_MODELS_GROUPS +
			'/' +
			modelGroupID +
			'/subjectGroups/' +
			subjectGroupID,
		data
	);
	return response.data;
};

const deleteCurriculumExamType = async (id: number, styleIDs: number) => {
	const response = await axiosClient.delete(
		api.CURRICULUM.CURRICULUM.LESSON_MODELS + '/' + id + '/credit/' + styleIDs
	);
	return response.data;
};

const service = {
	getLessonsModelsGroups,
	getLessonsModels,
	getLessonsModelById,
	createLessonsModelsGroup,
	createLessonsModels,
	generateSchedule,
	updateLessonsModelsGroup,
	updateLessonsModels,
	deleteLessonsModelsGroup,
	deleteLessonsModels,
	createLessonType,
	updateLessonType,
	deleteLessonType,
	createCurriculumExamType,
	updateCurriculumExamType,
	deleteCurriculumExamType,
	getCurriculumExamTypes,
	getLessonCurriculumInfo,
	getCurriculumSubjectGroups,
	updateCurriculumSubjectGroups
};

export default service;
