import {createContext, useReducer, Dispatch, ReactNode, useEffect, useContext, useRef} from 'react';
import SERVICES from 'services';
import {AuthContext} from './Auth.context';
import packageJSON from '../../package.json';

// function setBackgroundStylesInDOM(data: Record<string, any>) {
// 	const setKeys = [
// 		'background1',
// 		'background2',
// 		'background3',
// 		'buttonBackground1',
// 		'buttonBackground2',
// 		'buttonBackground3'
// 	];
// 	Object.entries(data).forEach(([key, value]: any) => {
// 		if (setKeys.includes(key) && value) {
// 			const style = document.createElement('style');
// 			style.innerHTML = `.checked_${key} {
// 				background-color: ${value};
// 			  }`;
// 			document.head.appendChild(style);
// 		}
// 	});
// }

export interface IAppConfigs {
	id: number;
	universityName_hy: string;
	universityName_en: string;
	universityName_ru: string;
	footerText: string;
	loginPageBackground: any;
	background1: string;
	background2: string;
	background3: any;
	buttonBackground1: string;
	buttonBackground2: string;
	needToCleanStorage?: boolean;
	buttonBackground3: string;
	creationDate: string;
	lastModifiedDate: string;
	systemTypeID: number;
}

export interface ISmtpConfigs {
	mailPort: number;
	mailHost: string;
	mailAuthUser: string;
	mailAuthPassword: string;
	creationDate: string;
	lastModifiedDate: string;
}

export interface ISystemFiles {
	id: number;
	path: string;
	creationDate: string;
	typeID: number;
	typeName: string;
}

const initialState = {
	menuItems: [],
	currentAcademicYearID: null,
	appConfigs: {} as IAppConfigs,
	filesConfigs: [],
	langs: [
		{
			locale: 'hy',
			name: 'Հայերեն',
			shortName: 'ՀԱՅ'
		}
	]
};

export interface PermissionsProps {
	post: 0 | 1;
	patch: 0 | 1;
	remove: 0 | 1;
}

export interface MenuItem {
	creationDate: string;
	delete: 0 | 1;
	get: 0 | 1;
	id: number;
	itemOrderNum: number;
	itemParentID: number;
	lastModifiedDate: string | null;
	page: string;
	pageId: number;
	post: 0 | 1;
	privilegieId: number;
	privilegies_name: string;
	patch: 0 | 1;
	children: MenuItem[] | [];
	url: string;
}

export interface ILNAG_TYPE {
	locale: string;
	name: string;
	shortName: string;
}

export interface SettingsStateProps {
	menuItems: MenuItem[] | any;
	currentAcademicYearID: null | number | any;
	appConfigs: IAppConfigs;
	filesConfigs: ISystemFiles[];
	langs: ILNAG_TYPE[];
}

type Action =
	| {type: 'GET_MENU'; payload: MenuItem[]}
	| {type: 'GET_CURRENT_ACADEMIC_YEAR_ID'; payload: MenuItem[]}
	| {type: 'GET_APP_CONFIGS'; payload: IAppConfigs}
	| {type: 'GET_FILES_CONFIGS'; payload: ISystemFiles[]}
	| {type: 'GET_LANGS'; payload: ILNAG_TYPE[]};

interface InitContextProps {
	state: SettingsStateProps;
	dispatch: Dispatch<Action>;
}

export const SettingsContext = createContext({} as InitContextProps);

const reducer = (state: SettingsStateProps, action: Action) => {
	switch (action.type) {
		case 'GET_MENU':
			return {
				...state,
				menuItems: action.payload
			};
		case 'GET_CURRENT_ACADEMIC_YEAR_ID':
			return {
				...state,
				currentAcademicYearID: action.payload
			};
		case 'GET_APP_CONFIGS':
			return {
				...state,
				appConfigs: action.payload
			};
		case 'GET_FILES_CONFIGS':
			return {
				...state,
				filesConfigs: action.payload
			};
		case 'GET_LANGS':
			return {
				...state,
				langs: action.payload
			};
		default:
			return state;
	}
};

const SettingsProvider = ({children}: {children: ReactNode}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const {state: authState} = useContext(AuthContext);
	const value = {state, dispatch};

	const prevFaviconPath = useRef<string>('');

	useEffect(() => {
		SERVICES.SETTINGS.getAppPublicConfigs().then(res => {
			dispatch({type: 'GET_APP_CONFIGS', payload: res.data});
			// setBackgroundStylesInDOM(res.data);
			const version = localStorage.getItem('version');
			if (packageJSON.needToCleanLocaleStorage && version !== packageJSON.version) {
				const currentLang = localStorage.getItem('language');
				localStorage.clear();
				localStorage.setItem('language', currentLang || 'hy');
			}
			localStorage.setItem('version', packageJSON.version);
		});
		SERVICES.SETTINGS.getFilesPublicConfigs().then(res => {
			dispatch({type: 'GET_FILES_CONFIGS', payload: res.data});
		});
		if (authState.isAuthenticated) {
			SERVICES.SETTINGS.getLanguagesList().then(res => {
				dispatch({
					type: 'GET_LANGS',
					payload: res.data?.map((langObject: any) => ({
						locale: langObject.lang,
						name: langObject[langObject.lang],
						shortName: langObject.shortName,
						...langObject
					}))
				});
			});
		}
	}, [authState.isAuthenticated]);

	useEffect(() => {
		const faviconPath = state.filesConfigs.find(file => file.typeID === 5);
		if (faviconPath !== undefined && faviconPath.path !== prevFaviconPath.current) {
			prevFaviconPath.current = faviconPath.path;
			let link = document.querySelector("link[rel~='icon']");
			if (!link) {
				link = document.createElement('link');
				(link as any).rel = 'icon';
				document.getElementsByTagName('head')[0].appendChild(link);
			}
			(link as any).href = process.env.REACT_APP_API_URL + '/' + faviconPath.path;
		} else if (faviconPath === undefined) {
			prevFaviconPath.current = '';
			let link = document.querySelector("link[rel~='icon']");
			if (!link) {
				link = document.createElement('link');
				(link as any).rel = 'icon';
				document.getElementsByTagName('head')[0].appendChild(link);
			}
			(link as any).href = '../assets/eUniversityLogo.png';
		}
		document.title =
			state.appConfigs[`universityName_${localStorage.getItem('language') as 'hy' | 'en' | 'ru'}`] ||
			state.appConfigs.universityName_hy ||
			'eUniversity';
	}, [state]);

	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export default SettingsProvider;
