import {Button, Form, Input} from 'antd';
import {AuthContext} from 'context/Auth.context';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
// import UFAR_Logo_white from 'assets/ufar-logo-white.svg';
import {SettingsContext} from 'context/Settings.context';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import services from 'services';
import './styles.less';

interface InputErrorsTypes {
	email: string;
	password: string;
}

interface UserTypes {
	isSubmitting: boolean;
	inputErrors: InputErrorsTypes | null;
}

const Login = () => {
	const {t} = useTranslation();
	const [user, setUser] = useState<UserTypes>({
		isSubmitting: false,
		inputErrors: null
	});
	const {dispatch} = useContext(AuthContext);
	const navigate = useNavigate();
	// const location = useLocation() as any;

	const {
		state: {appConfigs}
	} = useContext(SettingsContext);

	// let from = location.state?.from?.pathname || '/';

	const onFinish = (values: any) => {
		setUser({
			isSubmitting: true,
			inputErrors: null
		});
		services.AUTH.login(values)
			.then(res => {
				dispatch({
					type: 'LOGIN',
					payload: res.data
				});
				navigate('/', {replace: true});
			})
			.catch(err => {
				let errMessage = null;
				if (typeof err.message !== 'string') {
					errMessage = err.message;
				}
				setUser({
					isSubmitting: false,
					inputErrors: errMessage
				});
			});
	};

	return (
		<div className="regLayout">
			<div className="regLayout__header">
				<div className="regLayout__header-logo">
					{/* <img src={UFAR_Logo_white} alt="UFAR_Logo_white" width={168.57} /> */}
				</div>
				<div className="regLayout__header-menu"></div>
			</div>
			<div className="regLayout__main">
				<div className="regLayout__main_formContainer">
					<div className="regLayout__main_formContainer_title">
						<span>{t('signInTitle')}</span>
						<div id="divider" />
					</div>
					<Form
						className="regLayout__main_formContainer_form"
						layout="vertical"
						onFinish={onFinish}
						autoComplete="off">
						<Form.Item
							name="email"
							validateStatus={user.inputErrors?.email ? 'error' : undefined}
							help={user.inputErrors?.email}
							rules={[
								{
									required: true,
									message: t('errorMessage_enterEmail')
								},
								{type: 'email', message: t('errorMessage_incorrectValue')}
							]}>
							<Input
								placeholder={t('email')}
								className="regLayout__main_formContainer_form_input"
								type="email"
							/>
						</Form.Item>

						<Form.Item
							name="password"
							validateStatus={user.inputErrors?.password ? 'error' : undefined}
							help={user.inputErrors?.password}
							rules={[{required: true, message: t('errorMessage_enterPassword')}]}>
							<Input.Password
								placeholder={t('password')}
								className="regLayout__main_formContainer_form_input"
							/>
						</Form.Item>

						<Form.Item style={{textAlign: 'center'}}>
							<Button
								className="regLayout__main_formContainer_form_button"
								type="primary"
								htmlType="submit"
								loading={user.isSubmitting}>
								{t('signInBtn')}
							</Button>
						</Form.Item>
					</Form>
					{/* <div className="regLayout__main_formContainer_footer">
						<span>Not registered yet?</span>
						<a href="/register">Sign up now!</a>
					</div> */}
				</div>
			</div>
			<div className="regLayout__footer">
				© {moment().year()}, {appConfigs.footerText || t('footer_university')}
			</div>
		</div>
	);
};

export default Login;
