import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.use(Backend)
	.init({
		lng: localStorage.getItem('language') || 'hy',
		fallbackLng: 'hy',
		ns: ['translations'],
		defaultNS: 'translations',
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		parseMissingKeyHandler: key => `${key} is missing`,
		react: {
			useSuspense: true
		}
	});

export default i18n;
