import {Layout} from 'antd';
import FullScreenLoading from 'components/FullScreenLoading';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import SiderMenu from 'components/layout/Sider/Sider';
import {AuthContext} from 'context/Auth.context';
import {MenuItem, SettingsContext} from 'context/Settings.context';
import Login from 'pages/Login';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes} from 'react-router-dom';
import {LoginRoute, PrivateRoute} from 'routes/wrappers';
import SERVICES from 'services';
import 'antd/dist/antd.less';
import 'antd/dist/antd.variable.min.css';
import './App.less';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import {ConfigProvider} from 'antd';
import en from 'antd/es/locale/en_GB';
import ru from 'antd/lib/locale/ru_RU';
import hy from 'antd/lib/locale/hy_AM';
import fr from 'antd/lib/locale/fr_FR';

const langs: any = {
	hy,
	fr,
	en,
	ru
};

const {Content} = Layout;

const SettingsPageId = 9;
export interface RoutesObjectType {
	path: string;
	page: string;
	pageId: string;
	privilegies: string;
	component?: any;
	post: 0 | 1;
	patch: 0 | 1;
	remove: 0 | 1;
}

export let SYSTEM_TYPE_ID: number = 0;

function App() {
	const {i18n} = useTranslation();
	const {state: authState} = useContext(AuthContext);
	const {state: settingsState, dispatch: settingsDispatch} = useContext(SettingsContext);
	const [loading, setLoading] = useState(false);
	const [languagesLoading, setLanguagesLoading] = useState(true);

	SYSTEM_TYPE_ID = settingsState.appConfigs.systemTypeID;

	const getRoutesObjects = (routes: MenuItem[], initPath = '') => {
		return routes.reduce((acc: RoutesObjectType[], route) => {
			let path = initPath + route.url.slice(route.url.lastIndexOf('/'));

			if (route.children && route.children.length && route.pageId !== SettingsPageId) {
				acc = acc.concat(getRoutesObjects(route.children, path));
			} else {
				// if (path.split('/').length > 2 && route.patch) { // ADD A ROUTE FOR SINGLE PAGE FOR EACH EDITABLE TABLE
				// 	acc.push({
				// 		path: path + '/:id',
				// 		pageId: route.pageId.toString(),
				// 		page: route.page,
				// 		privilegies: route.privilegies_name,
				// 		post: route.post,
				// 		patch: route.patch,
				// 		remove: route.delete
				// 	});
				// }
				if (path === '/curriculum/main') {
					acc.push({
						path: path + '/:id',
						pageId: Number(999).toString(),
						page: route.page,
						privilegies: route.privilegies_name,
						post: route.post,
						patch: route.patch,
						remove: route.delete
					});
				}
				if (path === '/curriculum/lessonsList') {
					acc.push({
						path: path + '/:id',
						pageId: Number(333).toString(),
						page: route.page,
						privilegies: route.privilegies_name,
						post: route.post,
						patch: route.patch,
						remove: route.delete
					});
				}
				acc.push({
					path,
					pageId: route.pageId.toString(),
					page: route.page,
					privilegies: route.privilegies_name,
					post: route.post,
					patch: route.patch,
					remove: route.delete
				});
			}

			return acc;
		}, []);
	};

	const availableRoutesList = useMemo(() => {
		return getRoutesObjects(settingsState.menuItems);
	}, [settingsState]);

	const isRouteAvailable = (routePageId: string) => {
		return !!availableRoutesList.filter(route => route.pageId === routePageId).length;
	};

	useEffect(() => {
		if (languagesLoading) return;

		if (authState.isAuthenticated) {
			if (!settingsState.menuItems.length) setLoading(true);
			SERVICES.SETTINGS.getMenu()
				.then(res => {
					settingsDispatch({
						type: 'GET_MENU',
						payload: res.data
					});
				})
				.finally(() => setLoading(false));

			SERVICES.SETTINGS.getDictionary('curriculumAcademicYears').then(res => {
				const currentYear = new Date().getFullYear();
				const currentMonth = new Date().getMonth() + 1;

				const option = res.data.find((el: any) => {
					if (currentMonth >= 8) {
						return +el.name.substr(0, el.name.indexOf('-')) === currentYear;
					} else {
						return +el.name.substr(el.name.indexOf('-') + 1) === currentYear;
					}
				});

				if (option) {
					settingsDispatch({
						type: 'GET_CURRENT_ACADEMIC_YEAR_ID',
						payload: option.id
					});
				}
			});
		}
	}, [authState.isAuthenticated, i18n.language, languagesLoading]);

	useEffect(() => {
		const getLanguagesBundles = async () => {
			const AVAILABLE_LANGUAGES = settingsState.langs.map(lang => lang.locale);
			await Promise.all(
				AVAILABLE_LANGUAGES.map(async lng => {
					const res = await SERVICES.SETTINGS.getLanguages(lng);
					i18n.addResourceBundle(lng, 'translations', res.data, true, true);
				})
			);
		};

		getLanguagesBundles().finally(() => setLanguagesLoading(false));
	}, [settingsState.langs]);

	if (languagesLoading || loading || !i18n.isInitialized) return <FullScreenLoading size="large" />;

	return (
		<ConfigProvider locale={langs[i18n.language] || en}>
			<Layout>
				{authState.isAuthenticated && <SiderMenu />}
				<Layout className="layout">
					{authState.isAuthenticated !== undefined ? (
						<>
							{authState.isAuthenticated && <Header />}
							<Content className={`content ${authState.isAuthenticated && 'bordered-content'}`}>
								<Routes>
									{availableRoutesList.map(({path, pageId, post, patch, remove}) => {
										const formedPath = path
											.split('/')
											.map(i => i.slice(0, 1).toUpperCase() + i.slice(1))
											.join('/');
										let Component;
										if (formedPath === '/Curriculum/LessonsList/:id') {
											Component = React.lazy(
												() => import('pages/Curriculum/LessonsList/SingleLesson')
											);
										} else if (formedPath === '/Curriculum/Main/:id') {
											Component = React.lazy(
												() => import('pages/Curriculum/Main/SinglePlan')
											);
										} else if (formedPath.endsWith(':id')) {
											Component = React.lazy(() => import('components/SinglePage'));
										} else {
											Component = React.lazy(() => import(`pages${formedPath}`));
										}
										if (isRouteAvailable(pageId)) {
											return (
												<Route
													path={path === '/main' ? '/' : path}
													key={pageId}
													element={
														<PrivateRoute
															isAuthenticated={!!authState.isAuthenticated}>
															<Component
																permissions={{
																	post,
																	patch,
																	remove
																}}
															/>
														</PrivateRoute>
													}
												/>
											);
										}
									})}
									<Route
										path="/"
										element={
											<PrivateRoute isAuthenticated={!!authState.isAuthenticated}>
												<></>
											</PrivateRoute>
										}
									/>

									<Route
										path="/login"
										element={
											<LoginRoute isAuthenticated={!!authState.isAuthenticated}>
												<Login />
											</LoginRoute>
										}
									/>
									<Route
										path="/reset_password"
										element={
											<LoginRoute isAuthenticated={!!authState.isAuthenticated}>
												<ResetPassword />
											</LoginRoute>
										}
									/>
									<Route
										path="*"
										element={
											<PrivateRoute isAuthenticated={!!authState.isAuthenticated}>
												<div>NOT FOUND</div>
											</PrivateRoute>
										}
									/>
								</Routes>
							</Content>
							{!!authState.isAuthenticated && <Footer />}
						</>
					) : null}
				</Layout>
			</Layout>
		</ConfigProvider>
	);
}

export default App;
