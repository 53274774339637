enum API {
	GET_MENU = '/api/system/menu',
	GUIDE = '/api/guides',
	GET_CHARTS = '/api/system/charts',
	GET_SETTINGS_MENU = '/api/settings/menu',
	LANGS_KEY = '/api/settings/lang/key/main',
	GET_TRANSLATIONS = '/lang',
	GET_LANGUAGES = '/api/settings/lang',
	DICT = '/api/settings/dictionary',
	ADMISSION = '/api/settings/admission',
	UPDATE_PROFILE = '/api/profile',
	APPEALING_RANGE = '/api/settings/appealsVisibilitySettings',
	EXAMINATION_RANGE = '/api/settings/examinationPeriod',
	UPDATE_PASSWORD = '/api/profile/updatePassword',
	DIPLOMA_WORK_RANGE = '/api/settings/studentsDiplomaWorksRanges',
	APP_CONFIGS = '/api/settings/system/configs',
	APP_PUBLIC_CONFIGS = '/system/configs',
	FILES_PUBLIC_CONFIGS = '/system/files',
	FILES_CONFIGS = '/api/settings/system/files',
	MAIL_CONFIGS = '/api/settings/system/smpt'
}

export default API;
