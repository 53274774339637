import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const get = async (data: any) => {
	const response = await axiosClient.get(api.ASSIGNMENTS.ASSIGNMENTS, {params: data});
	return response.data;
};

const getAssignmentHistory = async (data: any, assignmentID: number) => {
	const response = await axiosClient.get(api.ASSIGNMENTS.ASSIGNMENTS_BY_ID + `${assignmentID}/history`, {
		params: data
	});
	return response.data;
};

const getByID = async (id: number) => {
	const response = await axiosClient.get(api.ASSIGNMENTS.ASSIGNMENTS_BY_ID + id);
	return response.data;
};

const create = async (data: any) => {
	const response = await axiosClient.post(api.ASSIGNMENTS.ASSIGNMENTS, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const update = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.ASSIGNMENTS.ASSIGNMENTS_BY_ID + id, formData(data));
	return response.data;
};
const attacheStudents = async (data: any, id: number) => {
	const response = await axiosClient.post(api.ASSIGNMENTS.ASSIGNMENTS_BY_ID + `${id}/answers`, data);
	return response.data;
};

const deleteByID = async (id: number) => {
	const response = await axiosClient.delete(api.ASSIGNMENTS.ASSIGNMENTS_BY_ID + id);
	return response.data;
};

/* Answers */
const getAnswers = async (data: any, assignmentID: number) => {
	const response = await axiosClient.get(api.ASSIGNMENTS.ASSIGNMENTS + `/${assignmentID}/answers`, {
		params: data
	});
	return response.data;
};
const deleteAnswers = async (ids: any, assignmentID: number) => {
	const response = await axiosClient.delete(
		api.ASSIGNMENTS.ASSIGNMENTS + `/${assignmentID}/answers/` + ids
	);
	return response.data;
};

const updateAnser = async (data: any, id: number, answerID: number) => {
	const response = await axiosClient.patch(
		api.ASSIGNMENTS.ASSIGNMENTS_BY_ID + `${id}/answers/${answerID}`,
		data
	);
	return response.data;
};

const getAnswerByID = async (id: number, assignmentID: number) => {
	const response = await axiosClient.get(
		api.ASSIGNMENTS.ASSIGNMENTS_BY_ID + `${assignmentID}/answers/${id}`
	);
	return response.data;
};

const service = {
	get,
	getAssignmentHistory,
	getByID,
	create,
	update,
	attacheStudents,
	deleteAnswers,
	deleteByID,
	getAnswers,
	getAnswerByID,
	updateAnser
};

export default service;
